import { defineComponent, ref, computed, watch,getCurrentInstance,onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm, useField} from "vee-validate";



interface Site {
  code: string;
  nom: string;
  longitude: string;
  latitude: string;
  entGeo : {
    id:string;
  };
}

interface EntGeo {
  id: string;

}
import useEmitter from '@/composables/useEmitter'
import { useBande } from "@/composables/useBande";
import { useEntGeos } from "@/composables/useEntGeos";
export interface BandeX {
  id: string;
  libelle: string;
  debut: number|undefined;
  fin: number|undefined;
}
export default defineComponent({
  name: "new-gsm-modal",
  props:{
    bande : {type:Object,default : {} as BandeX},
  },
  components: {ErrorMessage, Field},
  setup(props,context) {

    const emitter = useEmitter();
    const {getBandeDescription} = useBande("gsm");
    const {villesGabonaises} = useEntGeos();


    const showModal = ref(false);
    
    const siteA = ref('')
    const siteB = ref('')
    const edit = ref(false);
    const store       = useStore();
    const loading     = ref<boolean>(false);
    
    const siteData = ref<Site>({
          code: "A",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
    });
    
    const localite = ref("");
    var initialData : any = ref({});
    const localiteKey = ref<number>(0);

    //******/ COMPUTED PROPERTIES DBT

    const currentBande = computed(() => {
      // on retourne le tout
      return  "";
    });

    const title = computed(() => {
          return edit.value ? "Modification d'un site au réseau GSM" : "Ajout d'un site au réseau GSM";
    });

    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    const debutBande = computed(() => {
      return props.bande.debut;
    });

    const validationSchema =  Yup.object().shape({
            code: Yup.string().default('A'),
            nom: Yup.string().required().label("Le nom"),
            latitude: Yup.string().latitude().required().label("La latitude"),
            longitude: Yup.string().longitude().required().label("La longitude"),
            entGeo : Yup.object().shape({
             id : Yup.string().required().label("La ville" ),
            })
          }
    );

    //******/ COMPUTED PROPERTIES FIN

    const { resetForm, handleSubmit ,errors,values,meta } = useForm<Site>({
      validationSchema: validationSchema
    });



    onMounted(() => {
      initialData.value = meta.value.initialValues;      
    })

    const reset = () => {
      // Réinitialisation des données
      resetForm({values: meta.value.initialValues});
      edit.value = false
      showModal.value = false
    };


    const onClose= () => {
      reset()
    };
    
    const initModal = ( gsm : any  = undefined) => 
    {      
      edit.value = gsm?.value == undefined ? false : true;
      edit.value ? setDataToForm(gsm.value) : setDataToForm(undefined);
      localite.value = gsm?.value?.entGeo?.id;
      localiteKey.value++;
    }

    const submit = handleSubmit((values) => {
        
        emitter.emit(edit.value ? 'gsmSiteUpdated' : 'newGsmSiteAdded', values);
        showModal.value = false
    });

    const setDataToForm = (site: any = undefined) => {
      resetForm({
        values: {

            code: site != undefined ?  site.code : "A" ,
            nom: site != undefined ?  site.nom  : "",
            longitude: site != undefined ?  site.longitude  : "",
            latitude: site != undefined ?  site.latitude  : "",
            entGeo: {
              id: site != undefined ?  site.entGeo.id  : ""
            }
    
        }
      });
    }
    return {
      siteData,
      showModal,
      validationSchema,
      title,
      initialData,
      loading,
      meta,
      values,
      errors,
      actionButtonText,
      currentBande,
      villesGabonaises,
      onClose,
      initModal,
      submit,
      debutBande,
      siteA,
      siteB,
      localite,
      localiteKey
    };
  }
});
