
import type { ElTable } from 'element-plus'
import { defineComponent, computed,onMounted,ref} from "vue";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import _ from 'lodash';

export default defineComponent({
  name: "gsm-liste-recap",
  props: {
     data : {type:Array,default:[]},
  },
  components: {},
  setup(props, context) {
    

    const {getVilleDescription} = useEntGeos();



    return {
      getVilleDescription,
    };
  }
});
