import { Actions } from '@/store/enums/StoreEnums';
import { getCurrentInstance,computed,onMounted,ref,watch } from 'vue'
import { useStore } from "vuex";
//Plugin pour les events

export interface Bande {
    id: string|undefined;
    libelle: string;
    debut: number|undefined;
    fin: number|undefined;
  }
  
export function  useBande (typeReseau:string = "") {

    const store = useStore(); 
    const bandeAutreDebut = ref(undefined);
    const bandeAutreFin = ref(undefined);

    const currentBande = ref<Bande|undefined>({
      id:undefined,
      debut:undefined,
      fin:undefined,
      libelle:"",
    });

    const getBandes  = () => {
       return store.getters.bandes
    }
 
    onMounted(() => {
      //currentBande.value = undefined
    })

    // On surveille les changements sur la bande autre début
    watch(bandeAutreDebut,(nValue, oValue) => {
      //En cas de changement, on assigne sa valeur au début 
      // de la bande courante
      if(nValue != undefined && currentBande.value != undefined)
      {
  
        currentBande.value.debut = Number(nValue)

      }
    })

    // On surveille les changements sur la bande autre fin
    watch(bandeAutreFin, (nValue, oValue) => {
       //En cas de changement, on assigne sa valeur à la fin 
      // de la bande courante
      if(nValue != undefined && currentBande.value != undefined)
      {
        currentBande.value.fin = Number(nValue)
      }
      
    })

    // Réinitialise les propriétés de la bande
    const onBandeClear = () => {
      currentBande.value ={
        id:"",
        debut:undefined,
        fin:undefined,
        libelle:"",
      }
      bandeAutreFin.value = undefined
      bandeAutreDebut.value = undefined
      
    }

    // En cas de changement de bande
    const onBandeChange = () => {
      //On réinitialise ces propriétés
      bandeAutreFin.value = undefined
      bandeAutreDebut.value = undefined
    }

    const getBandeDescription  = (id:Bande|string, onlyExtension: boolean = false ) : string => {
          // console.log(id);
          
          var item = (typeof id === 'string') ?  getBande(id) : id as Bande
          if(item === undefined)
            return !onlyExtension ? "Bande inconnue" +" [ ? MHz -  ?GHZ]" : "" +" [ ? MHz -  ?GHZ]"
          else
          {            
              var fin : number = (item.fin === undefined)  ? 0 : item.fin
              var dbt : number = (item.debut === undefined)  ? 0 : item.debut
              var unitesDbt = isNaN(dbt) ? "0 MHz" : (dbt > 1000 ? (dbt/1000)+"Ghz" : (dbt)+" MHz")
              var unitesFin = isNaN(fin) ? "0 MHz" : (fin > 1000 ? (fin/1000)+"Ghz" : (fin)+" MHz")
              return !onlyExtension ? item.libelle +" ["+unitesDbt+ " - "+unitesFin+"]" : "" +" ["+unitesDbt+ " - "+unitesFin+"]"
          }
    }

    const getBandeParReseau  = (typeReseau:string): Array<Bande> => {
      var bandeDisponibles : Array<Bande> = []
      var network =  store.getters.reseaux.find(r => {

        return r.libelle.toLowerCase() == typeReseau.toLowerCase()
      });
      var bandeDisponibles : Array<Bande> =  network ? network.bandes.map(b => (
        {
          id:b.id,
          debut:b.debut,
          fin:b.fin,
          libelle:b.libelle,
          sousBandes : []
        } 
      )) : []
      
      return bandeDisponibles
    }

    const getBande  = (id:string): Bande|undefined => {
      var b = store.getters.bandes.find( v  => {
             return v.id == id
         })

        return  b == undefined ? 
        undefined
        :  
        {
          id:b.id,
          debut:b.debut,
          fin:b.fin,
          libelle:b.libelle,
        }
      }

    const bandeIsAutre  = (id:string|undefined): Boolean => {      
      return id != undefined &&  id == getBandeAutre().id
    }

    const getDefaultBande  = (): Bande => {
      return store.getters.defaultBande
    }

    const getBandeAutre  = (): Bande => {
      return store.getters.defaultBande
    }

    const bandeLimitIsSet  = (bande:any): Boolean => {
      var result : boolean = instanceOfBande(bande)
      if(result)
      {
       if(!isNaN(bande.debut) && !isNaN(bande.debut - 0) && !isNaN(bande.fin) && !isNaN(bande.fin - 0))
          result = true
        else
        {
          result = false
        }
      }
      return result
    }

    const instanceOfBande = (object: any): object is Bande => {
      return ('id' in object && 'libelle' in object && 'debut' in object && 'fin' in object)
    }

  
    return {
        getBande,
        getDefaultBande,
        getBandes,
        getBandeDescription,
        getBandeParReseau,
        bandeIsAutre,
        bandeLimitIsSet,
        bandeAutreDebut,
        bandeAutreFin,
        currentBande,
        onBandeClear,
        onBandeChange
    }
}
