

import { defineComponent, ref, computed, watch,getCurrentInstance,onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm, useField} from "vee-validate";
import useEmitter from '@/composables/useEmitter'
import {useSousBande} from '@/composables/useSousBande'
import {useBande} from '@/composables/useBande'
import { AutreBande, DirectionBande } from "@/core/models/enum/enumeration";
import {v4 as uuidv4} from 'uuid';
import { ElSelect } from "element-plus";

export enum SousBandeKey {
    sousBandeElementA = "sousBandeElementA",
    sousBandeElementB = "sousBandeElementB"
}


export interface coupleSousBande {
  id: String|undefined,
  sousBandeElementA : {
        sousBande: BandeX,
    directionBandeAutre: DirectionBande,
    finAutre : Number|undefined, 
    debutAutre: Number|undefined ,
  }, 
  sousBandeElementB :{
            sousBande: BandeX,
    directionBandeAutre: DirectionBande,
    finAutre : Number|undefined, 
    debutAutre: Number|undefined ,
   }
  }
  
  export interface SB {
    sousBande: BandeX,
    directionBande: DirectionBande,
    finAutre : Number|undefined, 
    debutAutre: Number|undefined ,
}  

export interface BandeX {
  id: string|undefined;
  debut: number|undefined;
  fin: number|undefined;
}
export default defineComponent({
  name: "new-sous-bande-modal",
  props:{
    bande : {type:Object,default : {} as BandeX},
  },
  components: {ErrorMessage, Field},
  setup(props,context) {

    const emitter = useEmitter();
     const instance = getCurrentInstance();
    
    const {sousBandeIsAutre, getSousBandeDescription} = useSousBande();
    const {getBandeDescription} = useBande();
    const showModal = ref(false);

    const sousBandeElementASousBandeRef = ref<InstanceType<typeof ElSelect>>()
    const sousBandeElementBSousBandeRef = ref<InstanceType<typeof ElSelect>>()

    const forceUpdateIndexA = ref(0)
    const forceUpdateIndexB = ref(0)

    const edit = ref(false);
    const store       = useStore();
    const loading     = ref<boolean>(false);


    // reset the field meta and its initial value and clears errors

    const coupleData = ref<coupleSousBande>({
       id:"",
       sousBandeElementA : {
        directionBandeAutre: DirectionBande.UL, 
        sousBande: {
           id:undefined,
           debut: undefined,
          fin: undefined,
        },
        debutAutre :  undefined,
        finAutre : undefined
       },
       sousBandeElementB : {
        directionBandeAutre: DirectionBande.DL, 
        sousBande: {
           id:undefined,
           debut: undefined,
          fin: undefined,
        },
        debutAutre : undefined,
        finAutre   : undefined
        }
    });

    var initialData : any = ref({})

    //******/ COMPUTED PROPERTIES DBT

    const currentBande = computed(() => {
      // on retourne le tout
      return  "";
    });

    const title = computed(() => {
          return edit.value ? "Modification d'un couple de sous-bandes" : "Ajout d'un couple de sous-bande";
    });

    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    const debutBande = computed(() => {
      return props.bande.debut;
    });

    const validationSchema =  Yup.object().shape({
        id : Yup.string().notRequired().label("L'id"),
        sousBandeElementA : Yup.object().shape({
          sousBande : Yup.object().shape({
          id: Yup.string().required().label("La sous-bande"),
          fin: Yup.number().notRequired().positive().label("La fin"),
          debut: Yup.number().notRequired().positive().label("Le début"),
          }).typeError("La sous-bande UL est obligatoire"),
          directionBandeAutre : Yup.string().required().equals(['UL']).default('UL'),
          finAutre: Yup.number().when('sousBande', {
          is: (sousBande) =>  {  
            if(sousBande)
            {
               return (sousBande.id != undefined && sousBande.id == AutreBande.SBUL);
            }
            else
            {
              return false
            }},
            then: (schema) => schema.required().positive()
            .when('debutAutre',{
              is : (value) =>  { return (value != undefined && value !='');},
              then:(schema) => schema.test(
                'bande-autre-fin-pgpp',
                '${path} doit être superieure au  début UL',
                (value, context) => value != undefined && value > context.parent.debutAutre)
                .test(
                  'bande-autre-dbt-pgpp-intervalle',
                  '${path} doit être dans l\'intervalle de la bande de référence',
                  (value, context) => value != undefined && value >= props.bande.debut && value <= props.bande.fin,
                )
            }),
            otherwise: (schema) => schema.notRequired(),
          }).label("La fin UL"),
          debutAutre: Yup.number().when('sousBande', {
            is: (sousBande) =>  {  
            if(sousBande)
            {
               return (sousBande.id != undefined && sousBande.id == AutreBande.SBUL);
            }
            else
            {
              return false
            }},
            then: (schema) => schema.required().positive()
            .when('finAutre',{
              is : (value) =>  { return (value != undefined && value !='');},
              then:(schema) => schema.test(
                  'bande-autre-dbt-pgpp',
                  '${path} doit être inferieure à la fin UL',
                  (value, context) => value != undefined && value < context.parent.finAutre,
                ).test(
                  'bande-autre-dbt-pgpp-intervalle',
                  '${path} doit être dans l\'intervalle de la bande de référence',
                  (value, context) => value != undefined && value >= props.bande.debut && value <= props.bande.fin,
                )
            }),
            otherwise: (schema) => schema.notRequired(),
          }).label("Le début UL"),
      }).typeError("La sous-bande est obligatoire"),
        sousBandeElementB : Yup.object().shape({

        directionBandeAutre : Yup.string().required().equals(['DL']).default('DL').label('La direction'),
        sousBande : Yup.object().shape({
         id: Yup.string().required().label("La sous-bande"),
         fin: Yup.number().notRequired().positive().label("La fin"),
         debut: Yup.number().notRequired().positive().label("Le début"),
        }).typeError("La sous-bande DL est obligatoire"),
        finAutre: Yup.number().when('sousBande', {
          is: (sousBande) => {  
            if(sousBande)
            {
               return (sousBande.id != undefined && sousBande.id == AutreBande.SBDL);
            }
            else
            {
              return false
            }
          },
          then: (schema) => schema.required().positive()
          .when('debutAutre',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
              'bande-autre-fin-pgpp',
              '${path} doit être superieure au début DL',
              (value, context) => value != undefined && value > context.parent.debutAutre)
              .test(
                  'bande-autre-dbt-pgpp-intervalle',
                  '${path} doit être dans l\'intervalle de la bande de référence',
                  (value, context) => value != undefined && value >= props.bande.debut && value <= props.bande.fin,
                )
          }),
          otherwise: (schema) => schema.notRequired(),
        }).label("La fin DL"),
        debutAutre: Yup.number().when('sousBande', {
          is: (sousBande) =>  {  
            if(sousBande)
            {
               return (sousBande.id != undefined && sousBande.id == AutreBande.SBDL);
            }
            else
            {
              return false
            }},
          then: (schema) => schema.required().positive()
          .when('finAutre',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
                'bande-autre-dbt-pgpp',
                '${path} doit être inferieure à la fin DL',
                (value, context) => value != undefined && value < context.parent.finAutre,
              ).test(
                  'bande-autre-dbt-pgpp-intervalle',
                  '${path} doit être dans l\'intervalle de la bande de référence',
                  (value, context) => value != undefined && value >= props.bande.debut && value <= props.bande.fin,
                )
          }),
          otherwise: (schema) => schema.notRequired(),
        }).label("Le début DL"),
      })
          }
    );

    //******/ COMPUTED PROPERTIES FIN

    const { resetForm, handleSubmit ,errors,values,meta ,setErrors, handleReset} = useForm<coupleSousBande>({
      validationSchema: validationSchema,
    });



    onMounted(() => {
      initialData.value = meta.value.initialValues
    })

    const reset = () => {
      resetCoupleData()
      edit.value = false
      showModal.value = false

    };

    const resetCoupleData = () => {
       coupleData.value.id = uuidv4()
            coupleData.value.sousBandeElementA= {
        directionBandeAutre: DirectionBande.UL, 
        sousBande: {
           id:undefined,
           debut: undefined,
          fin: undefined,
        },
        debutAutre : undefined,
        finAutre   : undefined
      } 
       coupleData.value.sousBandeElementB= {
        directionBandeAutre: DirectionBande.DL, 
        sousBande: {
           id:undefined,
           debut: undefined,
          fin: undefined,
        },
        debutAutre : undefined,
        finAutre   : undefined
      } 

      coupleData.value.sousBandeElementA.finAutre = undefined
      coupleData.value.sousBandeElementA.debutAutre = undefined
    }


    const onClose= () => {
      reset()
    };

    const onChangeSelect = (e, ref : string) =>   
    {


    }

    const initModal = ( gsm : any  = undefined) => 
    {
      instance?.proxy?.$forceUpdate();
      sousBandeElementASousBandeRef.value?.$forceUpdate()
      sousBandeElementBSousBandeRef.value?.$forceUpdate()
      edit.value = gsm.value == undefined ? false : true
      edit.value ? setDataToForm(gsm.value) : setDataToForm(undefined)
    }

    const submit = handleSubmit((values, actions) => {

       // emitter.emit(edit.value ? 'sousBandeUpdated' : 'newSousBandeAdded', values);

        if(edit.value)
        {
          context.emit('sousBandeUpdated', values)
        }
        else
        {
          context.emit('newSousBandeAdded', values)
        }
        showModal.value = false
    });

    /**
     * Verifie si une sous-bande autre est selectionnée
     * Pour afficher les champs de saisie des de début et de fin
     * @param  {Object} r   Une sous-bande
     * @param  {string} key La clé de l'object à surveiller
     * @return {Boolean} 
     */
    const showLimitInputs = (r : any,key: SousBandeKey = SousBandeKey.sousBandeElementA ) =>
    {
      var show :Boolean = false
        if(r.id != undefined)
              show = sousBandeIsAutre(r.id)


      return show
    }

    const setDataToForm = (coupleSousBande: any = undefined) => {

     
     // On force le rafraichissement des selects
     // des sous-bandes
      forceUpdateIndexA.value++
      forceUpdateIndexB.value++

      if(coupleSousBande == undefined)
      {

        handleReset()
        resetCoupleData()
      }
      else
      {
        
      resetForm({
        values: {
          id:coupleSousBande.id,
          sousBandeElementA : {
            directionBandeAutre: DirectionBande.UL, 
            sousBande: {
              id:coupleSousBande.sousBandeElementA.sousBande.id ,
              debut:  coupleSousBande.sousBandeElementA.sousBande.debut ,
              fin:  coupleSousBande.sousBandeElementA.sousBande.fin ,
            },
            debutAutre :   coupleSousBande.sousBandeElementA.debutAutre  ,
            finAutre :  coupleSousBande.sousBandeElementA.finAutre 
           },
           sousBandeElementB : {
            directionBandeAutre: DirectionBande.DL, 
            sousBande: {
              id: coupleSousBande.sousBandeElementB.sousBande.id ,
              debut:  coupleSousBande.sousBandeElementB.sousBande.debut,
              fin:  coupleSousBande.sousBandeElementB.sousBande.fin ,
            },
            debutAutre :   coupleSousBande.sousBandeElementB.debutAutre ,
            finAutre :  coupleSousBande.sousBandeElementB.finAutre
            }
    
        }
        
      });
      coupleData.value.id = coupleSousBande.id
      coupleData.value.sousBandeElementA= {
        directionBandeAutre: DirectionBande.UL, 
        sousBande: {
           id:coupleSousBande.sousBandeElementA.sousBande.id,
           debut: coupleSousBande.sousBandeElementA.sousBande.debut,
          fin: coupleSousBande.sousBandeElementA.sousBande.fin,
        },
        debutAutre : coupleSousBande.sousBandeElementA.debutAutre,
        finAutre   : coupleSousBande.sousBandeElementA.finAutre
      } 
       coupleData.value.sousBandeElementB= {
        directionBandeAutre: DirectionBande.DL, 
        sousBande: {
           id:coupleSousBande.sousBandeElementB.sousBande.id,
           debut: coupleSousBande.sousBandeElementB.sousBande.debut,
          fin: coupleSousBande.sousBandeElementB.sousBande.fin,
        },
        debutAutre : coupleSousBande.sousBandeElementB.debutAutre,
        finAutre   : coupleSousBande.sousBandeElementB.finAutre
      } 

      }

    }
    
     /**
     * Liste des sous-bandes disponibles pour
     * une bande donnée
     * 
     * @return {Array}
     */
    const sousBandes = computed(() =>
    {
      if(props.bande)
      {
        return store.getters.sousBandes
      }
      return []
    })

    /**
     * Liste des sous-bandes UL
     * @return {Array}
     */
    const sousBandesUL = computed(() =>
    {
      if(props.bande)
      {
        return sousBandes.value.filter(v => v?.directionBande =="UL")
      }
      return []
    })

    /**
     * Liste des sous-bandes DL
     * @return {Array}
     */
    const sousBandesDL = computed(() =>
    {
      if(props.bande)
      {
        return sousBandes.value.filter(v => v?.directionBande =="DL")
      }
      return []
    })



    return {
      coupleData,
      showModal,
      validationSchema,
      title,
      initialData,
      loading,
      meta,
      values,
      errors,
      handleReset,
      actionButtonText,
      currentBande,
      onClose,
      initModal,
      submit,
      debutBande,
      showLimitInputs,
      sousBandes,
      sousBandesDL,
      sousBandesUL,
      sousBandeIsAutre,
      getSousBandeDescription,
      getBandeDescription,
      onChangeSelect,
      sousBandeElementASousBandeRef,
      sousBandeElementBSousBandeRef,
      forceUpdateIndexA,
      forceUpdateIndexB
    };
  }
});


