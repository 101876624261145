import { defineComponent, computed,ref,watch,onMounted, onBeforeMount } from "vue";
import GsmListe from "./liste/GsmListe.vue"
import SousBandeGsmListe from "./sousBande/SousBandeGsmListe.vue"
import { Field, ErrorMessage,useField,useFieldArray } from "vee-validate";
import { useStore } from "vuex";
import { useBande } from "@/composables/useBande";
import { AlertMessage } from "@/bundle/AlertMessage";
import  BandeX from "@/core/models/bandeX";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { boolean } from "yup";


export default defineComponent({
  name: "gsm-form",
  props: {
    title : {type:String, required : false},
    subtitle : {type:String, required : false},
    icon : {type:String, required : false},
    edit : {type:Boolean, default : false},
    bandeId : {type:String, default : ""},
  },

  setup(props)
  {
    const { getBandeParReseau,bandeIsAutre,getBandeDescription,bandeAutreDebut,
     onBandeChange, onBandeClear,bandeAutreFin,currentBande:bande } = useBande("gsm")
    
    const gsmListe = ref<InstanceType<typeof GsmListe>>();
    const store = useStore(); 

    const sousBandeListeKey = ref(0);



    const { resetField:resetGsmBandeId,meta:metaBande,value:valueBandeId } = useField('gsm.bande.id');
    const { resetField:resetBande,meta:metaBandex,setErrors} = useField('gsm.bande');


    onBeforeMount(() => {

      // on initialises les sous bande
      if(!props.edit)
       store.dispatch(Actions.RESET_SOUS_BANDE, []);
      else
       store.dispatch(Actions.GET_SOUS_BANDE_PAR_BANDE, props.bandeId);

    })

    onMounted(()=>{

      setErrors([]);
      sousBandeListeKey.value++;

     })

    const hasEntries = computed(() => {
      return gsmListe.value?.hasData
    })

    watch(metaBande, (nValue, oValue) => {
      if(nValue.touched)
      {
      }
    })

    const bandes = computed(() => {
      return getBandeParReseau("gsm")
    })

    const onBandeClearLocal = (e) => {


      store.dispatch(Actions.RESET_SOUS_BANDE, []);
      onBandeClear()
    }

    const onBandeChangeLocal = (e) => {

      e["ligneSousBandes"] = [...e.sousBandes]
      delete e.sousBandes

     if(e)
     {
      if(e.id)
      {
        if(!bandeIsAutre(e.id))
        store.dispatch(Actions.GET_SOUS_BANDE_PAR_BANDE, e.id);
        else
        store.dispatch(Actions.RESET_SOUS_BANDE, []);

      }
      else
      {
        store.dispatch(Actions.RESET_SOUS_BANDE, []);
      }
     }
     else
     {
       store.dispatch(Actions.RESET_SOUS_BANDE, []);
     }
      onBandeChange()
    }

    return{
      bandes,
      bandeIsAutre,
      sousBandeListeKey,
      bandeAutreDebut,
      getBandeDescription,
      bandeAutreFin,
      bande,
      onBandeClearLocal,
      onBandeChange,
      onBandeChangeLocal,
      metaBande,
      gsmListe,
      hasEntries,
      metaBandex,
    }
  },
  components: {
    Field,
    ErrorMessage,
    GsmListe,
    SousBandeGsmListe,
  }
});
