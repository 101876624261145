import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gsm_form = _resolveComponent("gsm-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_gsm_form, {
      ref: "gsmFormRef",
      icon: _ctx.showStepLabel.icon,
      title: _ctx.showStepLabel.title,
      edit: _ctx.edit,
      bandeId: _ctx.bandeInfo.gsm.bande.id,
      subtitle: _ctx.showStepLabel.subtitle
    }, null, 8, ["icon", "title", "edit", "bandeId", "subtitle"])
  ]))
}