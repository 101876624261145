export class RegexList  {
    
    public static readonly LATITUDE : RegExp = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[0-8][0-9])(?:(?:\.[0-9]{1,6})?))\s{0,1}([°])\s{0,1}(?:((?:60(?:(?:\.0{1,6})?)|(?:[0-9]|[0-5][0-9])(?:(?:\.[0-9]{1,6})?)))\s{0,1}['′]\s{0,1}(?:((?:60(?:(?:\.0{1,6})?)|(?:[0-9]|[0-5][0-9])(?:(?:\.[0-9]{1,6})?)))\s{0,1}(["″]|''))?\s{0,1})?([NS])?\s{0,1}$/g;
    public static readonly LONGITUDE : RegExp = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[0-9][0-9]|[1][0-7][0-9])(?:(?:\.[0-9]{1,6})?))\s{0,1}([°])\s{0,1}(?:((?:60(?:(?:\.0{1,6})?)|(?:[0-9]|[0-5][0-9])(?:(?:\.[0-9]{1,6})?)))\s{0,1}['′]\s{0,1}(?:((?:60(?:(?:\.0{1,6})?)|(?:[0-9]|[0-5][0-9])(?:(?:\.[0-9]{1,6})?)))\s{0,1}(["″]|''))?\s{0,1})?([EW])?\s{0,1}$/g;
    public static readonly BOITE_POSTALE : RegExp = /^[0-9]*$/
    public static readonly NUMERO_TELEPHONE_GABONAIS : RegExp = /^((\+|00)241[\s]{0,1}|0)(60|62|65|66|74|76|77|11)(?:(?:[\s]?\d{2}){3}|\d{2}(?:[\s]?\d{3}){2})$/
    public static readonly BASE64_FORMAT : RegExp = /^(.*)(base64,)(.*)$/i;
    public static readonly PHONES_FORMATS_MASK : Array<string> = ['### ## ## ##'];
    public static readonly NO_WHITE_SPACE: RegExp =/^[^\n ]*$/
    public static readonly NUMBER_WITH_QUOTES_OR_NOT: RegExp =/^[0-9]*$|'\d+'|"\d+"/

}