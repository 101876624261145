import { computed } from 'vue'
import { useStore } from "vuex";

//Plugin pour les events
export function  useEntGeos () {
    const store = useStore(); 

    const villes = computed(() => {
        return store.getters.villes;
    });
    const entGeos = computed(() => {
      return store.getters.entgeos;
  });
    //La liste des villes Gabonaises
    const villesGabonaises = computed( () => {
      return  store.getters.entgeos;
    });

    // La liste des pays du monde
    const pays = computed( () => {
      return  store.getters.pays;
    });

    // La liste des pays du monde sans le Gabon
    const paysSansLeGabon = computed( () => {
      // return  pays.value.filter(p => {
      //   return p.nom.trim().toUpperCase() != "GABON"
      // });
      return  store.getters.pays;
    });
        
    const getEntGeo  = (id:string) => {
      return entGeos.value.find( v => {
           return v?.id == id
       })
    }
    const getEntGeoParent  = (id:string) => {
        return getEntGeo(id).parent
      }
      const getVilleInterationaleDescription  = (id:string,nomVilleInternationale:string) => {
        var pays = getEntGeo(id)
        var message = "Pays inconnu"
        if(pays)
        {
          message = nomVilleInternationale + " , "+pays.nom
        }
         return message
    }

    const getVilleDescription  = (id:string, affichePays:boolean = false) => {
        var ville = getEntGeo(id)
        var message = "Ville inconnue"
        if(ville)
        {
          var province = ville.parent.parent
          var pays = province ? province.parent : ""
          message = ville.nom + " , " + province ? province.nom : "" + " "+(affichePays ? ", "+pays.nom : "")
        }
          return message
    }

    const getEntgeoDescription  = (id:string, affichePays:boolean = false) => {
      var region = getEntGeo(id)
      var message = "Région inconnue"
      if(region)
      {
        message = region.nom + " , "+region.parent.nom
      }
      
      return message
    }

    const getProvince = (entGeo) => {



    }

    return {
        entGeos,
        getEntGeo,
        getVilleDescription,
        getVilleInterationaleDescription,
        getEntGeoParent,
        villesGabonaises,
        pays,
        paysSansLeGabon,
        villes,
        getEntgeoDescription
    }
}