import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface Result{
  isConfirmed : boolean;
  isDenied : boolean;
  dismiss: "cancel"
  isDismissed:  boolean;
}
export class AlertMessage {
   
   //proprietes
   static coreOptions : any = {
    title: "",
    html:  "",
    icon: "success",
    confirmButtonText: "D'accord",
    cancelButtonText: "Annuler",
    customClass: 
    {
      confirmButton: "btn btn-primary btn-lg",
      cancelButton: "btn btn-secondary btn-lg",
    }
   }

    //Methodes
    public static onSuccess(message = "", title = "",  options = {}) 
    {
        let baseOptions = {title:title,html:message,icon: "success",}
        this.coreOptions = {...options, ...this.coreOptions,...baseOptions}

        return new Promise((resolve, reject) =>
        {
            Swal.fire(this.coreOptions).then((d) => 
              {
                resolve(d)
              }).catch( (e) =>
                {
                reject(e)
                }
              )
        });
    }

    public static onError(message = "", options = {}) 
    {

      let baseOptions = {html:message,icon: "error",}
      this.coreOptions = {...options, ...this.coreOptions,...baseOptions}

      return new Promise((resolve, reject) =>
      {
          Swal.fire(this.coreOptions).then((d) => 
            {
              resolve(d)
            }).catch( (e) =>
              {
              reject(e)
              }
            )
      });
    }

    public static onWarning(message = "", title = "",  options = {}) 
    {
        let baseOptions = {title:title,html:message,icon: "warning",}
        this.coreOptions = {...options, ...this.coreOptions,...baseOptions}

        return new Promise<Result>((resolve, reject) =>
        {
            Swal.fire(this.coreOptions).then((d) => 
              {
                var res  = {} as Result
                res.isConfirmed = d.isConfirmed
                resolve(d)
              }).catch( (e) =>
                {
                reject(e)
                }
              )
        });
    }

    public static ajaxRequest(message = "", title = "",  options = {}) 
    {
        let baseOptions = {title:title,html:message,icon: "warning",}
        this.coreOptions = {...options, ...this.coreOptions,...baseOptions}

        return new Promise<Result>((resolve, reject) =>
        {
          Swal.fire({
              title: 'Submit your Github username',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Look up',
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
                return fetch(`//api.github.com/users/${login}`)
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(response.statusText)
                    }
                    return response.json()
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  })
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: `${result.value.login}'s avatar`,
                  imageUrl: result.value.avatar_url
                })
              }
            })
        });
    }
}