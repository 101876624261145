
import { defineComponent} from "vue";

export default defineComponent({
  name: "observation-recap",
  components: {},
  props: {
    hasData: Boolean,
    value: String,
  },
});
