
    export interface BandeX {
        id: string;
        libelle: string;
        debut: number;
        fin: number;
      }
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ObservationRecap from "@/components/customs/ObservationRecap.vue"
import GsmListeRecap from "@/components/networks/gsm/form/liste/GsmListeRecap.vue"
import GsmSousBandeListeRecap from "@/components/networks/gsm/form/sousBande/GsmSousBandeListeRecap.vue"
import { useBande } from "@/composables/useBande";
import { useSousBande } from "@/composables/useSousBande";

export default defineComponent({
  name: "gsm-recap",
  props: {
     data : {type:Object, default:null}
  },
    setup(props){
    const store   = useStore();
    const { getBandeDescription} =useBande("gsm");
    const { sousBandeIsAutre } =useSousBande();


    const hasObservation =computed(() => {
      return props.data.gsm.observation != "" && props.data.gsm.observation != undefined && props.data.gsm.observation != null
    })

    const bandeDescription =computed(() => 
    {
      return props.data.gsm.bande ? getBandeDescription(props.data.gsm.bande) : "Non précisée"
    })

    
    return { 
      hasObservation,
      bandeDescription,
      sousBandeIsAutre
      
    }
  },
  components: {ObservationRecap,GsmListeRecap, GsmSousBandeListeRecap},
});
