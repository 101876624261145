

import { AutreBande, DirectionBande, TypeBande } from "../enum/enumeration";
import { Site } from "@/core/models/Site";
import * as Yup from "yup";
import { TypeDocument } from "../TypeDocument";

interface Bande
{
  debut: number|undefined,
  fin: number|undefined,
  id: string,
  libelle: string
}

interface BandeX
{
  debut: number|undefined,
  fin: number|undefined,
  id: string,
  ligneSousBandes:Array<{}>|undefined;
  libelle: string
}
export class StepGsm { 
    //field 
    largeurBande: number|undefined;
    bande: BandeX;
    numeroAutorisation: string|undefined;

    bandeAutreLibelle:string|undefined;
    bandeAutreFin:number|undefined;
    bandeAutreDebut:number|undefined;
    sites: Array<Site>;
    observation: string|undefined;


    //constructor 
    constructor() { 

        this.observation = ""
        this.sites = []
        this.largeurBande = undefined 
        this.numeroAutorisation = undefined
        this.bande = {
            debut: undefined,
            fin: undefined ,
            libelle: "" ,
            id: "",
            ligneSousBandes: []

          }
        this.bandeAutreLibelle = undefined;
        this.bandeAutreFin = undefined;
        this.bandeAutreDebut = undefined;
    }  

    static validationSchema() {
        
        return Yup.object({
            numeroLicence: Yup.string().label("Le n° de licence"),
            gsm: Yup.object().shape
            ({
              numeroAutorisation: Yup.string().required().label("Le n° d'autorisation"),
              observation: Yup.string().notRequired().label("L'observation'"),
              largeurBande: Yup.number().positive().required().label("La largeur de la bande"),

              bande : Yup.object().shape({
                id: Yup.string().required().label("La bande"),
                libelle: Yup.string().notRequired().label("Le nom de la bande"),
                debut: Yup.number().notRequired().positive().label("Le début"),
                fin: Yup.number().notRequired().positive().label("La fin"),
                ligneSousBandes : Yup.array().of(Yup.object().shape({
                })).min(0,'La liste des sous-bandes doit avoir au moins 1 sous-bande.')
             .strict()
             .label('La Liste des sous-bandes')
              }).typeError("La bande est obligatoire").not([undefined]),

              bandeAutreLibelle: Yup.string().notRequired().label("La BA"),
              bandeAutreFin: Yup.number().when('bande.id', {
                is: (id) => { return (id == AutreBande.BD);},
                then: (schema) => schema.required().positive()
                .when('bandeAutreDebut',{
                  is : (value) =>  { return (value != undefined && value !='');},
                  then:(schema) => schema.test(
                    'bande-autre-fin-pgpp',
                    '${path} doit être superieure à celle du début',
                    (value, context) => value != undefined && value > context.parent.bandeAutreDebut)
                }),
                otherwise: (schema) => schema.notRequired(),
              }).label("La fréquence de fin"),
              bandeAutreDebut: Yup.number().when('bande.id', {
                is: (id) => { return (id == AutreBande.BD);},
                then: (schema) => schema.required().positive()
                .when('bandeAutreFin',{
                  is : (value) =>  { return (value != undefined && value !='');},
                  then:(schema) => schema.test(
                      'bande-autre-dbt-pgpp',
                      '${path} doit être inferieure à celle de fin',
                      (value, context) => value != undefined && value < context.parent.bandeAutreFin,
                    )
                }),
                otherwise: (schema) => schema.notRequired(),
              }).label("La fréquence de début"),
              sites  : Yup.array().of(Yup.object().shape({
                        nom : Yup.string().required().min(2).max(60).label("Le nom"),
                })).min(1,'La liste des sites doit avoir au moins 1 élèment.')
                   .strict()
                   .label('La liste des sites')
            })
      })
    }
 }