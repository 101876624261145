

import {useSousBande} from "@/composables/useSousBande";
import {  defineComponent } from "vue";

export default defineComponent({
  name : "gsm-sous-bande-liste-recap",
  props : {
     data : {type:Array,default:[]},
  },
  setup(props)
  {
     const { getSousBandeDescription,sousBandeIsAutre} = useSousBande()
     
     return{
      getSousBandeDescription,sousBandeIsAutre
     }
  }
})
