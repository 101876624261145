import * as Yup from "yup"
import Reference from "yup/lib/Reference";
import { AnyObject, Maybe } from "yup/lib/types";
import { RegexList } from "../RegexList";


// Valide la latitude au format Degré Minutes Secondes
Yup.addMethod<Yup.StringSchema>(Yup.string, "latitude", function () {
  return Yup.string().matches(RegexList.LATITUDE, {
    message: "La latitude doit être au format DMS",
    excludeEmptyString: true
  })
});

// Valide la longitude au format Degré Minutes Secondes
Yup.addMethod<Yup.StringSchema>(Yup.string, "longitude", function () {
    return Yup.string().matches(RegexList.LONGITUDE, {
      message: "La longitude doit être au format DMS",
      excludeEmptyString: true
    })
});
// Valide la longitude au format Degré Minutes Secondes
Yup.addMethod<Yup.StringSchema>(Yup.string, "noWhiteSpace", function () {
  return Yup.string().matches(RegexList.NO_WHITE_SPACE, {
    message: "${path} ne doit contenir aucun espace",
    excludeEmptyString: true
  })
});
// Valide la numeros de téléphone gabonais
Yup.addMethod<Yup.StringSchema>(Yup.string, "numerotationGabonaise", function () {
    return Yup.string().matches(RegexList.NUMERO_TELEPHONE_GABONAIS, {
      message: "Le Numero de téléphone doit être au format gabonais",
      excludeEmptyString: true
    })
});
Yup.addMethod<Yup.NumberSchema>(Yup.number, "inclusDansBandeFrequence", function (debut:number|undefined,fin:number|undefined, message: string = "") {
  var dbShow =  debut==undefined ? " ? MHz " : (debut > 1000 ? (debut/1000).toFixed(2) +"GHz" : debut + "MHz")
  var finShow =  fin==undefined ? " ? MHz" : (fin > 1000 ? (fin/1000).toFixed(2) +"GHz" : fin + "MHz")
  return  this.test(
  'inclusDansBandeFrequence',
  "${path} est hors de la bande ["+dbShow+" - "+""+finShow +"]" ,
   value => {return value!=undefined && fin != undefined && debut != undefined   && value <= fin && value >= debut
   })
});


declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    latitude(): StringSchema<TType, TContext>;
    longitude(): StringSchema<TType, TContext>;
    numerotationGabonaise():StringSchema<TType, TContext>;
    noWhiteSpace():StringSchema<TType, TContext>;
  }
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    inclusDansBandeFrequence(debut:number|undefined,fin:number|undefined): NumberSchema<TType, TContext>;
  }

}
export default Yup;