import { DirectionBande } from '@/core/models/enum/enumeration';
import { Actions } from '@/store/enums/StoreEnums';
import { getCurrentInstance,computed,onMounted,ref,watch } from 'vue'
import { useStore } from "vuex";
//Plugin pour les events

export interface SousBande {
    id: string;
    debut: number|undefined;
    fin: number|undefined;
    bandeAutreFin : number|undefined;
    bandeAutreDebut : number|undefined;
    directionBande:string;

  }
  export interface Bande {
    id: string;
    libelle: string;
    debut: number|undefined;
    fin: number|undefined;
  }
  
export function  useSousBande (bandeId:string|undefined = undefined) {

    const store = useStore(); 
    const currentSousBande = ref<SousBande>({
      id:"",
      debut:undefined,
      directionBande:"",
      fin:undefined,
      bandeAutreFin : undefined,
      bandeAutreDebut : undefined
    });

    const getSousBandes  = () => {
       return store.getters.bandes
    }


    // Réinitialise les propriétés de la bande
    const onSousBandeClear = () => {
      currentSousBande.value ={
        id:"",
        debut:undefined,
        fin:undefined,
        directionBande :"",
        bandeAutreFin : undefined,
        bandeAutreDebut : undefined
      }
      
    }

    // En cas de changement de bande
    const onSousBandeChange = () => {
      //On réinitialise ces propriétés
    }

    const getSousBandeDescription  = (id:SousBande|string, onlyExtension: boolean = false ) : string => {
          var item = (typeof id === 'string') ?  getSousBande(id) : id as SousBande
          if(item === undefined || item === null)
            return !onlyExtension ? "Bande inconnue" +" [ ? MHz -  ?GHZ]" : "" +" [ ? MHz -  ?GHZ]"
          else
          {
              var fin : number = (item.fin === undefined)  ? 0 : item.fin
              var dbt : number = (item.debut === undefined)  ? 0 : item.debut
              var unitesDbt = isNaN(dbt) ? "0 MHz" : (dbt > 1000 ? (dbt/1000)+"Ghz" : (dbt)+" MHz")
              var unitesFin = isNaN(fin) ? "0 MHz" : (fin > 1000 ? (fin/1000)+"Ghz" : (fin)+" MHz")
              if(sousBandeIsAutre(item.id))
              return !onlyExtension ? "Autre "+item.directionBande+" [? MHz- ? MHz]" : "Autre "+"" +item.directionBande+" [? MHz - ? MHz]"
              else
              return !onlyExtension ? item.directionBande+" ["+unitesDbt+ " - "+unitesFin+"]" : "" +item.directionBande+" ["+unitesDbt+ " - "+unitesFin+"]"

          }
    }

    const getSousBande  = (id:string): SousBande|undefined => {
        return store.getters.sousBandes.find( v  => {
             return v.id == id
         })
    }

    const sousBandeIsAutre  = (id:string|undefined, sousBandeIsNull : Boolean = false): Boolean => {

      if(sousBandeIsNull) return true
      return id !== undefined && ( id == getBandeAutreUL().id || id == getBandeAutreDL().id)
    }

    const getBandeAutreUL  = (): SousBande => {
      return store.getters.defaultBandeUL
    }

    const getBandeAutreDL  = (): SousBande => {
      return store.getters.defaultBandeDL
    }

    const bandeLimitIsSet  = (bande:any): Boolean => {
      var result : boolean = instanceOfBande(bande)
      if(result)
      {
       if(!isNaN(bande.debut) && !isNaN(bande.debut - 0) && !isNaN(bande.fin) && !isNaN(bande.fin - 0))
          result = true
        else
        {
          result = false
        }
      }
      return result
    }

    const instanceOfBande = (object: any): object is SousBande => {
      return ('id' in object && 'libelle' in object && 'debut' in object && 'fin' in object)
    }

     /**
     * Liste des sous-bandes disponibles pour
     * une bande donnée
     * 
     * @return {Array}
     */
      const sousBandes = computed(() =>
      {
        if(bandeId)
        {
          return store.getters.sousBandes
        }
        return []
      })
  
      /**
       * Liste des sous-bandes UL
       * @return {Array}
       */
      const sousBandesUL = computed(() =>
      {
        if(bandeId)
        {
          return sousBandes.value.filter(v => v?.directionBande =="UL")
        }
        return []
      })
  
      /**
       * Liste des sous-bandes DL
       * @return {Array}
       */
      const sousBandesDL = computed(() =>
      {
        if(bandeId)
        {
          return sousBandes.value.filter(v => v?.directionBande =="DL")
        }
        return []
      })
  
    return {
        getSousBandes,
        getSousBandeDescription,
        sousBandeIsAutre,
        bandeLimitIsSet,
        sousBandesDL,
        sousBandesUL,
        currentSousBande,
        onSousBandeClear,
        onSousBandeChange,
    }
}
