import Yup from "./YupMethod"
import printValue from 'yup/es/util/printValue';

Yup.setLocale({
     mixed: {
        default: '${path} est invalide',
        required: '${path} est obligatoire',
        oneOf: '${path} doit être une des valeurs suivantes: ${values}',
        notOneOf: '${path} ne doit pas être une des valeurs suivantes: ${values}',
        notType: ({ path, type, value, originalValue }) => {
          let isCast = originalValue != null && originalValue !== value;
          let msg =""
          if(type==="number")
          {
            msg = `${path} doit être numérique`
          }
          else if(type==="string")
          {
            msg = `${path} doit être une chaine de caractère`
          }
          else if(type==="array")
          {
            msg = `${path} doit être un tableau`
          }
          else if(type==="object")
          {
            msg = `${path} doit être un objet`
          }
          else if(type==="boolean")
          {
            msg = `${path} doit être vrai ou faux`
          }
          else
          {

            msg =`${path} doit être de type \`${type}\` , ` +
            `mais la valeur finale est de: \`${printValue(value, true)}\`` +
            (isCast
              ? ` (convertir la valeur de \`${printValue(originalValue, true)}\`).`
              : '.');
            if (value === null) {
              msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
            }
          }


          return msg;
        },
        defined: '${path} doit être défini',
      },
 number : {
    min: '${path} doit être supérieur à ou égal à ${min}',
    max: '${path} doit être inférieur ou égal à ${max}',
    lessThan: '${path} doit être inférieur ${less}',
    moreThan: '${path} doit être supérieur à ${more}',
    positive: '${path} doit être un nombre positif',
    negative: '${path} doit être un nombre négatif',
    integer: '${path} doit être un entier naturel',
 },
string: {
    length: '${path} doit avoir exactement ${length} caractères',
    min: '${path} doit avoir au moins ${min} caractères',
    max: '${path} doit avoir au plus ${max} caractères',
    matches: '${path} doit correspondre au format : "${regex}"',
    email: '${path} doit avoir un format valide',
    url: '${path} doit être une URL valide',
    uuid: '${path} doit être un UUID valide',
    trim: '${path} doit être a trimmed string',
    lowercase: '${path} doit être une chaine de caractères en miniscule',
    uppercase: '${path} doit être une chaine de caractères en majuscule',
  },
  
 date:  {
    min: '${path} field doit être later than ${min}',
    max: '${path} field doit être at earlier than ${max}',
  },
  
   boolean: {
    isValue: '${path} doit être ${value}',
  },
   object:  {
    noUnknown: '${path} a des clés non indentifiées: ${unknown}',
  },
   array: {
    min: '${path}  doit avoir au moins ${min} élèments',
    max: '${path} doit être inférieur ou égal à ${max} élèments',
    length: '${path} doit avoir ${length} élèments',
  }
})

export default Yup;