
import { defineComponent, computed, ref} from "vue";
import GsmRecap from "@/components/networks/gsm/recap/auth/GsmRecap.vue";

import { Field, ErrorMessage,useField} from "vee-validate";
import { useEntGeos } from "@/composables/useEntGeos";

export default defineComponent({
  name: "step-3",
  props: {
     data : {type:Object, default:null},
     currentStep : {type:Boolean, default:false}
  },
  setup(props){
    
    const {getVilleDescription} = useEntGeos();
    const confirmed = ref(false)
    
    return{
      confirmed,
      getVilleDescription
      
    }
  },
  components: {Field, ErrorMessage, GsmRecap},
});
