
import { defineComponent, computed, onMounted, ref } from "vue";
import GsmForm from "@/components/networks/gsm/form/GsmForm.vue";

export default defineComponent({
  name: "step-1",
  props: { bandeInfo: Object, edit :Boolean },
  setup(props){

    // Affichage du nom du tableau
    const gsmFormRef = ref<typeof GsmForm>();

    // Affichage du nom du tableau
    const showStepLabel = computed(() => 
    {
      let subtitle = "Veuillez préciser les détails du réseau"
      let title = "Réseau Gsm",
          icon = "las la-wifi fs-1";
      return {title, subtitle, icon}
    });

    onMounted(() => {
      if(gsmFormRef.value)
      {       
        gsmFormRef.value.bande.ligneSousBandes = {...props.bandeInfo?.gsm.ligneSousBandes};

        gsmFormRef.value.bande = {...props.bandeInfo?.gsm.bande};
      }
      
    });
        
    return{
      showStepLabel,
      gsmFormRef
    }
  },
  components: {
    GsmForm
  },
});
